import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/portfolio/wander_login.png";
import IMG2 from "../../assets/portfolio/wander_reg.png";
import IMG3 from "../../assets/portfolio/spark_media1.jpg";
import IMG4 from "../../assets/portfolio/spark_media2.jpg";
import IMG5 from "../../assets/portfolio/spark_media3.jpg";
import IMG6 from "../../assets/portfolio/where_next_filter.gif";
import IMG7 from '../../assets/portfolio/intro_app.jpg';
import IMG8 from '../../assets/portfolio/weather_app.jpg';
import IMG9 from '../../assets/portfolio/ecommerce-site.jpg';
import IMG10 from '../../assets/portfolio/pokemon-pokedex.gif';


// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    id: 1,
    image: IMG9,
    title: "Ecommerce Site",
    description: "This is an Ecommerce website that I created with React, Next.js, Sanity CMS and Stripe integration. Through this site a user is able to add items to a cart and purchase them through the Stripe checkout API. All of the items are managed through Sanity CMS, leading to a fully customizable experience.",
    github: "https://ecommerce-site-zeta.vercel.app",
  },
  {
    id: 2,
    image: IMG1,
    title: "Wander",
    description: "Wander is a mobile application created in React Native, designed for IOS and Android. Wander is an application created for solo travellers to swipe, match and chat with other users within their local area or whilst abroad to meet likeminded travellers who wish to share experiences with another person. \nInspired by the dating app Tinder, this application is marketed towards the backpacker demographic. Users will be able to filter aspects such as the type of travel they are looking for (E.g. Cultural, Sporty, Food, Partying, Historical, etc.).",
    github: "https://github.com/samodavey/Wander",
  },
  {
    id: 3,
    image: IMG4,
    title: "SPARK Media",
    description: "During my time at WiFi Spark I was chosen by a project manager to spearhead the development of SPARK Media. This was essentially a web application which would be deployed to NHS Hospitals for patients to stream TV, Radio, Films and magazines from their bedside on a tablet provided to them. (Along with administrator tools to tailor the user experience).",
    github: "https://www.wifispark.com/media",
  },
  {
    id: 4,
    image: IMG6,
    title: "Where Next? SparkAR Filter",
    description: "As a personal project I have been working on face filters using Facebook's AR software Spark AR Studio. Orginally as a task set out by my current company INTRO Travel, my task was to create two filters to get customers excited for travelling again post pandemic (Leading to the creation of my \"Where next? & \"Type of traveller\" filters).",
    github: "https://www.instagram.com/ar/763175560879379/",
  },
  {
    id: 5,
    image: IMG7,
    title: "INTRO App",
    description: "During my time working for INTRO Travel, I took it upon myself to improve the existing application used to promote their tours and also saw this as an opportunity to improve my React skills. The basis of the application is to provide information of tours, #smallchange (A socially conscientous initiative), meet the leader and a contact section.",
    github: "https://github.com/samodavey/IntroTravelApp",
  },
  {
    id: 6,
    image: IMG8,
    title: "Weather App",
    description: "This is one of my first React apps I worked on to improve my skills. With assistance from the Open Weather API in this simplistic app the user is able to search up their local area (E.g. London) and the app will provide the temperature, humidity, visibility and image depicting the current conditions. I aim to return to this project or start from scratch to make an improved product.",
    github: "https://github.com/samodavey/WeatherApp",
  },
  {
    id: 7,
    image: IMG10,
    title: "Pokemon Pokedex",
    description: "I wanted to experiment with using API's on personal projects, the example above is of my Pokemon Pokedex project. Quite simply this retrieves information from the publicly available \"PokeAPI\" and displays relevant information below the search area. I aim to improve this project with neater aesthetics and additions such as playing their sounds and potentially experiment with other various API's.",
    github: "https://github.com/samodavey/pokemon-pokedex",
  },
];


// IMPLEMENT A SWIPERJS FEATURE TO SHOW MORE IMAGES IN ARTICLE TAG
const photos = [
  {
    key: 1,
    project: 'wander',
    photos: [IMG1, IMG2, IMG3],
  },
  {
    key: 2,
    project: 'sparkmedia',
    photos: [IMG4, IMG5, IMG6],
  }
];

const portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio_container">
        {data.map(({ id, image, title, description, github, demo }) => {
          return (
            <article key={id} className="portfolio_item">
              <div className="portfolio_item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <small>{description}</small>
              <div className="portfolio_item-cta">
                <a href={github} className="btn" target="_blank">
                  Link
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default portfolio;
