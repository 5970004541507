import React from "react";
import "./about.css";
import ME from "../../assets/about.png";
import { FaAward } from "react-icons/fa";
import { BiUser } from "react-icons/bi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt="About Image"/>
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <FaAward className="about_icon" />
              <h5>Experience</h5>
              <small>2+ Years Working</small>
            </article>
            <article className="about_card">
              <BiUser className="about_icon" />
              <h5>Connections</h5>
              <small>200+ Worldwide</small>
            </article>
            <article className="about_card">
              <VscFolderLibrary className="about_icon" />
              <h5>Projects</h5>
              <small>20+ Completed Commercial Projects</small>
            </article>
          </div>
          <p>
          I am a dedicated, optimistic and ambitious individual who is pursuing his goal of becoming a well known Developer recognised by a vast community.
          When given a task I always strive to give every effort I have to achieve my targets and go beyond the expected in order to provide a better product.
          Teamwork and presentation are key aspects I always attempt to demonstrate well to an audience as I personally believe these are vital within any industry.
          </p>
          {/* Edit this ^^^^ */}
          <a href="#contact" className="btn btn-primary">Let's Talk</a>
        </div>
      </div>
    </section>
  );
};

export default About;
