import React from 'react'
import './contact.css'
import {MdMailOutline} from 'react-icons/md'
import {RiMessengerFill} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import {useRef} from 'react';
import emailjs from 'emailjs-com'

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_iv9j6ti', 'template_zy19kxl', form.current, '9YUhK1BTI1pbkOEJ1');

    e.target.reset();
  };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact_container">
        <div className="contact_options">
          <article className="contact_option">
            <MdMailOutline className="contact_option-icon"/>
            <h4>Email</h4>
            <h5>samkdavey@gmail.com</h5>
            <a href="mailto:samkdavey@gmail.com" target="_blank">Send a message</a>
          </article>
          <article className="contact_option">
            <RiMessengerFill className="contact_option-icon"/>
            <h4>Messenger</h4>
            <h5>Sam Davey</h5>
            <a href="https://m.me/samkdavey" target="_blank">Send a message</a>
          </article>
          <article className="contact_option">
            <BsWhatsapp className="contact_option-icon"/>
            <h4>WhatsApp</h4>
            <h5>+44 7568360797</h5>
            <a href="https://api.whatsapp.com/send?phone=+447568360797" target="_blank">Send a message</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Full Name" required/>
          <input type="email" name="email" placeholder="Your Email" required/>
          <input type="text" name="subject" placeholder="Subject" required/>
          <textarea name="message" rows="7" placeholder="Your Message" required></textarea>
          <button type="submit" className="btn btn-primary">Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact
