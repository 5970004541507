import React from 'react'
import {BsLinkedin, BsGithub, BsTwitter} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className="header_socials">
      <a href="https://www.linkedin.com/in/sam-davey/" target="_blank"><BsLinkedin/></a>
      <a href="https://github.com/samodavey?tab=repositories" target="_blank"><BsGithub/></a>
      <a href="https://twitter.com/TravelWithDavey" target="_blank"><BsTwitter/></a>
    </div>
  )
}

export default HeaderSocials
